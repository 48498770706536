<template>
  <div class="v-stack h-stretch gap-3">
    <TableGear
      v-on:rowSelected="$router.push('/pricelist/item/detail/' + $event._id)"
    ></TableGear>
  </div>
</template>

<script>
import TableGear from "@/components/TableGear.vue";

export default {
  components: {
    TableGear,
  },
};
</script>
